import React from "react";
import { Suspense, lazy, ElementType } from "react";
import { Navigate, useRoutes, Outlet } from "react-router-dom";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import AccessGuard from "../guards/AccessGuard";

// config
import { PATH_AFTER_LOGIN } from "../config";

// components
import LoadingScreen from "../components/LoadingScreen";

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "set-up",
          element: <Setup />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "dashboard",
          children: [
            { path: "", element: <Dashboard /> },
            { path: "campaign", element: <Campaign /> },
          ],
        },
        {
          path: "profile",
          children: [{ path: "settings", element: <ProfileSettings /> }],
        },
        {
          path: "admin",
          children: [
            {
              path: "users",
              element: (
                <AccessGuard type={["iFrog"]}>
                  <UserManagement />
                </AccessGuard>
              ),
            },
            {
              path: "accounts",
              element: (
                <AccessGuard type={["iFrog"]}>
                  <AccountManagement />
                </AccessGuard>
              ),
            },
          ],
        },
      ],
    },
    { path: "/", element: <Navigate to="/dashboard" replace /> },
    { path: "/page-not-found", element: <NotFound /> },
  ]);
}

// IMPORT COMPONENTS
const Login = Loadable(lazy(() => import("../pages/Login")));
const Setup = Loadable(lazy(() => import("../pages/Setup")));
const ResetPassword = Loadable(lazy(() => import("../pages/ResetPassword")));
const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));
const Campaign = Loadable(lazy(() => import("../pages/Campaign")));
const ProfileSettings = Loadable(lazy(() => import("../pages/ProfileSettings")));
const UserManagement = Loadable(lazy(() => import("../pages/UserManagement")));
const AccountManagement = Loadable(lazy(() => import("../pages/AccountManagement")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
